.App {
  text-align: center;
  /* background-image: url('./imgs/hubbleDeepField.jpg'); */
  position: fixed;
  width: 100%;
  height: 100%;
  /* padding-bottom: 20px; */
  overflow-y: scroll;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: scroll;
  /* padding-bottom: 20px; */
}

body {
  background-color: black;
  background-image: url('./imgs/hubbleDeepField.jpg');
  background-size: cover;
  position: absolute;
  overflow-y: scroll;
  overflow: auto;
}

.main-container {
  /* background-color: black; */
  /* position: absolute; */
  /* display: grid;
  grid-auto-rows: 1 10px; */
  color: #D6D6D6;
  justify-content: center;
  width: 83%;
  max-width: 800px;
  margin: auto;
  height: fit-content;
  overflow: auto;
  overflow-y: scroll;
  /* height: 100%; */
  /* padding-left: 10px;
  padding-right: 10px; */

  /* border: 4px solid #C724B1;
  border-radius: 7px; */
}

.second-container {
  background-color: black;
  position: relative;
  /* display: grid;
  grid-auto-rows: 1 10px; */
  color: #D6D6D6;
  justify-content: center;
  /* width: 100%; */
  /* right: 3px; */
  /* bottom: 4px; */
  height: fit-content;
  min-height: 55vh;
  width: auto;

  /* padding-left: 0;
  padding-right: 0; */
  padding: 8px;
  margin-bottom: 20px;

  border-bottom: 4px solid #C724B1;
  border-left: 4px solid #C724B1;
  border-right: 4px solid #C724B1;
  border-radius: 0 0 7px 7px;
}

.pb-50 {
  padding-bottom: 50px;
}

.text-align-left {
  text-align: left;
}

.padding-md {
  padding: 20px 30px 50px 30px;
}

.div-1 {
  padding-left: 32px;
  padding-right: 16px;
}

.h3-1 {
  margin-bottom: 14px;
}

.h3-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.header {
  display: inline-block;
  width: 100%;
  height: max-content;
  overflow: auto;
}

.footer {
  /* position: fixed; */
  display: block;
  width: 100%;
  height: 40px;
  /* position:fixed; */
  /* left:0px;
  bottom:0px; */
}

.navbar {
  /* background-color: black; */
  position: relative;
  top: 1px;
  /* border: 4px solid #C724B1;
  border-radius: 7px 7px 0px 0px; */
  /* margin-left: 4px; */
  /* right: 3px; */
  /* display: inline-block; */
}

.nav-item-wrapper {
  background-color: black;
}

.nav-item-wrapper1-selected {
  background-color: black;
  display: inline-block;
  width: 50%;
  /* right: 4px; */
  /* top: 1px; */

  box-sizing: border-box;

  padding-bottom: 4px;

  border-top: 4px solid #C724B1;
  border-left: 4px solid #C724B1;
  border-right: 4px solid #C724B1;
  border-radius: 7px 7px 0 0;
  /* padding-left: 4%; */
}

.nav-item-wrapper1-selected::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(100% - 4px);
  left: calc(50% - 3.5px);
  /* bottom: 8px; */
  /* padding-top: -4px; */

  background-color: #0074D9;
  height: 0px;
  width: 0px;
  border-radius: 0 0 0 7px;
  border-left: 4px solid #C724B1;
  border-bottom: 4px solid #C724B1;
  /* border-top-left-radius: 25px; */

  box-shadow: -1px 1px 0 0 black, 0 1px 0 0 black, -1px 0 0 0 black, -2px 2px 0 0 black, -3px 3px 0 0 black;
  /* z-index: 100; */
}

.nav-item-wrapper2-selected {
  background-color: black;
  display: inline-block;
  width: 50%;
  /* right: 4px; */
  /* top: 1px; */

  box-sizing: border-box;

  padding-bottom: 4px;

  border-top: 4px solid #C724B1;
  border-left: 4px solid #C724B1;
  border-right: 4px solid #C724B1;
  border-radius: 7px 7px 0 0;
  /* padding-left: 4%; */
}

.nav-item-wrapper2-selected::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(100% - 4px);
  left: 50%;
  /* bottom: 8px; */
  /* padding-top: -4px; */

  background-color: #0074D9;
  height: 0px;
  width: 0px;
  border-radius: 0 0 7px 0;
  border-right: 4px solid #C724B1;
  border-bottom: 4px solid #C724B1;
  /* border-top-left-radius: 25px; */

  box-shadow: 1px 1px 0 0 black, 0 1px 0 0 black, 1px 0 0 0 black, 2px 2px 0 0 black, 3px 3px 0 0 black;
  /* z-index: 100; */
}

.nav-item-wrapper1-unselected {
  text-shadow: 1px 1px 0 #C724B1, -1px 1px 0 #C724B1;
  /* -webkit-text-stroke: 2px #C724B1; */
  display: inline-block;
  width: 50%;
  /* right: 4px; */
  /* top: 1px; */

  box-sizing: border-box;

  padding-top: 4px;

  border-bottom: 4px solid #C724B1;
  /* padding-left: 4%; */
}

.nav-item-wrapper1-unselected::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(100% - 4px);
  left: 0%;
  /* bottom: 8px; */
  /* padding-top: -4px; */

  background-color: #0074D9;
  height: 0px;
  width: 0px;
  border-radius: 7px 0 0 0;
  border-left: 4px solid #C724B1;
  border-top: 4px solid #C724B1;
  /* border-top-left-radius: 25px; */

  box-shadow: -1px -1px 0 0 black, 0 -1px 0 0 black, -1px 0 0 0 black, -2px -2px 0 0 black;
  /* z-index: 100; */
}

.nav-item-wrapper2-unselected {
  text-shadow: 1px 1px 0 #C724B1, -1px 1px 0 #C724B1;
  /* -webkit-text-stroke: 2px #C724B1; */
  display: inline-block;
  width: 50%;
  /* right: 4px; */
  /* top: 1px; */

  box-sizing: border-box;

  padding-top: 4px;

  border-bottom: 4px solid #C724B1;
  /* padding-left: 4%; */
}

.nav-item-wrapper2-unselected::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(100% - 4px);
  left: calc(100% - 3.7px);
  /* bottom: 8px; */
  /* padding-top: -4px; */

  background-color: #0074D9;
  height: 0px;
  width: 0px;
  border-radius: 0 7px 0 0;
  border-left: 4px solid #C724B1;
  border-bottom: 4px solid #C724B1;
  /* border-top-left-radius: 25px; */

  box-shadow: 1px -1px 0 0 black, 0 -1px 0 0 black, 1px 0 0 0 black, 2px 2px 0 0 black;
  /* z-index: 100; */
}

.nav-item-wrapper1 {
  display: inline-block;
  width: 50%;
  /* right: 4px;
  top: 1px; */

  box-sizing: border-box;

  border-top: 4px solid #C724B1;
  border-left: 4px solid #C724B1;
  border-right: 4px solid #C724B1;
  border-radius: 7px 7px 0 0;
  /* padding-left: 4%; */
}

.nav-item-wrapper2 {
  display: inline-block;
  width: 50%;
  /* left: 4px;
  top: 1px; */

  box-sizing: border-box;

  border-top: 4px solid #C724B1;
  border-left: 4px solid #C724B1;
  border-right: 4px solid #C724B1;
  border-radius: 7px 7px 0 0;
  /* padding-right: 4%; */
}

.nav-item {
  display: inline-block;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav-item1 {
  display: inline-block;
  width: 100%;
}

.nav-item2 {
  width: 50%;
}

p {
  margin: 0;
  padding: 16px;
}

.link-text {
  color: #D6D6D6;
}
.link-text:link { text-decoration: none; }
.link-text:visited { text-decoration: none; }
.link-text:hover { text-decoration: none; }
.link-text:active { text-decoration: none; }

.header-text {
  color: #D6D6D6;
}

.mint-page-heder {
  padding: 0px;
  padding-top: 10px;

  color: #CCCCCC;
  font-size: 0.9em;
}

.connect-wallet-page-heder {
  padding: 0px;
  padding-top: 10px;

  color: #CCCCCC;
}

.connect-btn {
  display: block;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 18px;
  background-color: black;
  color: #D6D6D6;

  cursor: pointer;

  border: 2px solid #C724B1;
  border-radius: 4px;
}

.mint-widget-box {
  margin: 16px auto 40px;
  padding: 12px;
  height: 100%;
  width: 300px;
  max-width: 50%;
  min-width: fit-content;
  border: 1px solid #C2C2C2;
}

.mint-widget-header {
  font-weight: bold;
  font-size: 1.2em;
}

.plus-btn {
  background-color: black;
  color: #D6D6D6;
  width: 1.68em;
  /* text-align: center; */
  /* vertical-align: bottom; */
  /* line-height: 1.5em; */
  /* padding-top: 1.5px; */
  padding-left: 5.4px;
  padding-bottom: 0.6px;

  margin-left: 3px;
  margin-right: 3px;

  border: 2px solid #C724B1;
  border-radius: 100%;
}

.minus-btn {
  background-color: black;
  color: #D6D6D6;
  width: 1.68em;

  padding-top: 0.6px;
  padding-left: 3px;
  padding-right: 3px;

  margin-left: 8px;
  margin-right: 3px;

  border: 2px solid #C724B1;
  border-radius: 100%;
}

.mint-amount {
  width: 3em;
  margin-left: 3px;
  margin-right: 3px;
  background-color: black;
  color: #D6D6D6;

  border: 1px solid #C2C2C2;
  border-radius: 5px;

  /* Firefox */
  -moz-appearance: textfield;

  z-index: 100;
}
/* Chrome, Safari, Edge, Opera */
.mint-amount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mint-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mint-cost {
  /* color: #B4B4B4; */
  font-size: 0.9em;
  margin-bottom: 15px;
}

.mint-btn {
  background-color: black;
  color: #D6D6D6;

  cursor: pointer;

  font-size: 1em;

  margin-left: 3px;
  margin-right: 3px;

  border: 2px solid #C724B1;
  border-radius: 4px;
}

.nft-card-box {
  box-sizing: border-box;
  display: inline-block;
  width: fit-content;

  border: 2px solid #C724B1;
  border-radius: 4px;
  padding: 10px 7px 10px 7px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.nft-card-img {
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 350px;
  max-width: 50vw;
  max-height: 50vw;
  margin-left: auto;
  margin-right: auto;
  /* margin: 7px; */
  margin-bottom: 15px;
  display: block;
}

.nft-attribute {
  white-space: pre-wrap;

  box-sizing: border-box;
  font-size: 0.68em;
  display: inline-block;

  cursor: default;

  border: 2px solid #C724B1;
  border-radius: 4px;

  margin: 3px;
  padding: 4px;

  width: fit-content;
}

.nft-name-text {
  cursor: default;
}

.attribute-text {
  width: fit-content;
  display: block;

  cursor: default;
}

.footer-text {
  color: #D6D6D6;
  font-size: 0.8em;
}

.pd4 {
  padding: 4px;
}

.m4 {
  margin: 4px;
}

.loading {
  /* font-weight: bold; */
  display:inline-block;
  margin: 8px;
  /* font-family: monospace; */
  /* font-size: 30px; */
  clip-path: inset(0 1.2ch 0 0);
  animation: l 2s steps(5) infinite;
}

.tx-processing-status {
  color: #B4B4B4;
  font-size: 0.8em;

  margin-top: -11px;
  margin-bottom: 15px;
}

.results-subtext {
  color: #B4B4B4;
  font-size: 0.8em;

  /* margin-top: -4px; */
  margin-bottom: 10px;
}

@keyframes l {
  to {
    clip-path: inset(0 -1.2ch 0 0)
  }
}



/* Loading spinner */
.loading-spinner {
  position: relative;
  top: 2px;
  height: 1.2em;
  width: 1.2em;
}

/* Fade in and out animations */
.fade-in {
  animation: fadeIn ease 1.5s;
  -webkit-animation: fadeIn ease 1.5s;
  -moz-animation: fadeIn ease 1.5s;
  -o-animation: fadeIn ease 1.5s;
  -ms-animation: fadeIn ease 1.5s;
}
@keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

.fade-out {
  animation-delay: 5s;
  animation: fadeOut ease 1.5s;
  -webkit-animation: fadeOut ease 1.5s;
  -moz-animation: fadeOut ease 1.5s;
  -o-animation: fadeOut ease 1.5s;
  -ms-animation: fadeOut ease 1.5s;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
